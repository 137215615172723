import React from "react";
import { IconName, SortingType, FlatButton, Variant, TableColumnV2 } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { User } from "@technis/shared";
import { get, isString } from "lodash";

export const getUsersTableColumns = (
  onClickEdit: (userId: string) => void,
  onClickDelete: (userId: string) => void,
  onClickViewAsUser: (userId: string) => void,
): TableColumnV2[] => [
  {
    key: "name",
    header: i18n.t(translation.search.users),
    type: SortingType.STRING,
  },
  {
    key: "email",
    header: i18n.t(translation.search.email),
    type: SortingType.STRING,
  },
  {
    key: "organization",
    header: i18n.t(translation.search.organization),
    type: SortingType.STRING,
  },
  {
    key: "status",
    header: i18n.t(translation.search.status),
    render: () => <>Active</>, // TODO: Fix when backend is ready
    // type: SortingType.STATUS,
  },
  {
    key: "id",
    header: i18n.t(translation.search.id),
    type: SortingType.STRING,
  },
  {
    key: "userId",
    header: i18n.t(translation.search.actions),
    sortable: false,
    render: (userId) => {
      if (isString(userId))
        return (
          <div className="actions">
            <FlatButton iconName={IconName.USER} text={i18n.t(translation.search.viewAsUser)} colorVariant={Variant.ACCENT} onClick={() => onClickViewAsUser(userId)} />
            <FlatButton iconName={IconName.PEN} text={i18n.t(translation.search.edit)} colorVariant={Variant.PRIMARY} onClick={() => onClickEdit(userId)} />
            <FlatButton iconName={IconName.TRASH} text={i18n.t(translation.search.delete)} colorVariant={Variant.SECONDARY} onClick={() => onClickDelete(userId)} />
          </div>
        );
    },
  },
];

export const getUserRows = (users: Partial<User>[], columns: ReturnType<typeof getUsersTableColumns>) => {
  const keys = columns.map(({ key }) => key);

  return users.map((user) => ({
    rows: keys.reduce((acc, key) => ({ ...acc, [key]: get(user, key, "") }), {}),
  }));
};
