import React from "react";
import { IconName, SortingType, FlatButton, Variant, TableColumnV2 } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { DateFormats, Installation, isDefined } from "@technis/shared";
import { get, isNumber } from "lodash";
import moment from "moment";

export const getInstallationsTableColumns = (onClickInstallationData: (installationId: number) => void, onClickSupportData: (installationId: number) => void): TableColumnV2[] => [
  {
    key: "name",
    header: i18n.t(translation.search.installations),
    type: SortingType.STRING,
  },
  {
    key: "organization",
    header: i18n.t(translation.search.organization),
    type: SortingType.STRING,
  },
  {
    key: "address",
    header: i18n.t(translation.search.address),
    type: SortingType.STRING,
  },
  {
    key: "creationDate",
    header: i18n.t(translation.search.creationDate),
    type: SortingType.STRING,
    render: (creationDate) => {
      if (isNumber(creationDate)) {
        if (!isDefined(creationDate)) return "-";
        return moment(creationDate).format(DateFormats.UNIVERSAL);
      }
    },
  },
  {
    key: "installationId",
    header: i18n.t(translation.search.actions),
    sortable: false,
    render: (installationId) => {
      if (isNumber(installationId))
        return (
          <div className="actions">
            <FlatButton
              iconName={IconName.FILE_CHART_COLUMN}
              text={i18n.t(translation.search.installationData)}
              colorVariant={Variant.ACCENT}
              onClick={() => onClickInstallationData(installationId)}
            />
            <FlatButton
              iconName={IconName.ENVELOPE}
              text={i18n.t(translation.search.supportData)}
              colorVariant={Variant.ACCENT}
              onClick={() => onClickSupportData(installationId)}
            />
          </div>
        );
    },
  },
];

export const getInstallationsRows = (installations: Partial<Installation>[], columns: ReturnType<typeof getInstallationsTableColumns>) => {
  const keys = columns.map(({ key }) => key);

  return installations.map((installation) => ({
    rows: keys.reduce((acc, key) => ({ ...acc, [key]: get(installation, key, "") }), {}),
  }));
};
