import React from "react";
import { Modal, Size, FlatButton, FilledButton, Variant, ComponentType, SubTitle } from "@technis/ui";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { UserService } from "@services/userService";
import { displayToast } from "@redux/toast/toast.slice";

type DeleteUserModalProps = {
  userId: string;
  isModalVisible: boolean;
  onCloseModal: () => void;
  onSuccessDelete: () => void;
};

export const DeleteUserModal = ({ userId, isModalVisible, onCloseModal, onSuccessDelete }: DeleteUserModalProps) => {
  const dispatch = useDispatch();

  const onClose = () => {
    onCloseModal();
  };

  const onConfirm = async () => {
    try {
      await UserService.deleteUser(userId);
      onClose();
      onSuccessDelete();
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.deleteUserSuccessMessage),
          variant: ComponentType.SUCCESS,
        }),
      );
    } catch {
      onClose();
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.deleteUserErrorMessage),
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  return (
    <Modal
      actionsElement={
        <div className="modal-footer">
          <FlatButton onClick={onClose} text={i18n.t(translation.users.cancel)} size={Size.LARGE} className="cancel-button" />
          <FilledButton onClick={onConfirm} text={i18n.t(translation.search.delete)} colorVariant={Variant.SECONDARY} size={Size.LARGE} />
        </div>
      }
      size={Size.MEDIUM}
      shown={isModalVisible}
      onClose={onClose}
    >
      <SubTitle text={i18n.t(translation.users.deleteUserConfirmation)} size={Size.MEDIUM} />
    </Modal>
  );
};
