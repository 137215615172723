import { useCookies } from "react-cookie";

import { AuthService } from "@services/authService";
import { COOKIE_AUTH_VIEW_AS_USER, COOKIE_DOMAIN } from "@common/cookie";
import { ROUTES } from "@routes/config";

export enum Dashboards {
  LEGACY = "LEGACY",
  VISION = "VISION",
}

const dashboardAddresses = {
  [Dashboards.LEGACY]: process.env.DASHBOARD_LEGACY_URL,
  [Dashboards.VISION]: process.env.DASHBOARD_VISION_URL,
};

export const useViewAsUser = (dashboard: Dashboards) => {
  const [_, setCookie] = useCookies([COOKIE_AUTH_VIEW_AS_USER]);
  const viewAsUser = async (userId: string) => {
    const {
      data: { getViewAsUserToken },
    } = await AuthService.getViewAsUserToken(userId);
    const token = getViewAsUserToken;
    setCookie(COOKIE_AUTH_VIEW_AS_USER, token, { path: ROUTES.ROOT, domain: COOKIE_DOMAIN, secure: true });
    setTimeout(() => {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: dashboardAddresses[dashboard],
        rel: "noopener noreferrer",
      }).click();
    }, 1);
  };

  return { viewAsUser };
};
