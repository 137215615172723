import { DashboardConfigurationJSON } from "@technis/shared";
import Ajv from "ajv";
import schema from "./schema.json";

export const validateConfig = (config: string | Partial<DashboardConfigurationJSON>) => {
  try {
    const json = typeof config === "string" ? JSON.parse(config) : config;
    const ajv = new Ajv({ allErrors: true, verbose: true });

    return ajv.validate(schema, json);
  } catch {
    return false;
  }
};
