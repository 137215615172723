import React, { ClipboardEvent, ChangeEvent, KeyboardEvent } from "react";
import { AppTheme, useAppTheme } from "@common/Theme";

const NAVIGATION_KEYS = {
  backward: ["Backspace", "ArrowLeft"],
  forward: ["ArrowRight"],
};

const isValueValid = (value: string) => isFinite(Number(value)) || !value;

/**
 * Returns the first char of the string that is not {valueToReplace}
 * If the string only contains {valueToReplace} chars, it will return this char
 * @param value
 * @param charToIgnore
 * @returns {*}
 */
const getNewChar = (value: string, charToIgnore: string) => value.split("").filter((char) => char !== charToIgnore)[0] || charToIgnore;

type OtpInputContainerProps = {
  value: string;
  index: number;
  handleChange: (index: number, value: string) => void;
  handleNavigation: (index: number, key: string) => void;
  onPaste: (event: ClipboardEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  theme: AppTheme;
  inputRef: React.LegacyRef<HTMLInputElement>;
};

const OtpInputContainer = ({ value, index, handleChange, handleNavigation, onPaste, isDisabled, theme, inputRef }: OtpInputContainerProps) => {
  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => handleChange(index, target.value);

  const onKeyUp = ({ key }: KeyboardEvent<HTMLInputElement>) => handleNavigation(index, key);

  return (
    <input
      ref={inputRef}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      disabled={isDisabled}
      className="otpInput"
      style={{ backgroundColor: theme.base.colors.light, color: theme.base.font.color }}
    />
  );
};

type OtpInputsProps = {
  otp: string[];
  setOtp: (otp: React.SetStateAction<string[]>) => void;
  otpLength: number;
  isDisabled: boolean;
};

export const OtpInputs = ({ otp, setOtp, otpLength, isDisabled }: OtpInputsProps) => {
  const { theme } = useAppTheme();
  const otpTextInputs: HTMLInputElement[] = [];

  const handleNavigation = (index: number, key: string) => {
    if (NAVIGATION_KEYS.backward.includes(key) && index > 0) otpTextInputs[index - 1].focus();
    if (NAVIGATION_KEYS.forward.includes(key) && index < otpTextInputs.length - 1) otpTextInputs[index + 1].focus();
  };

  const handleChange = (index: number, value: string) => {
    if (!isValueValid(value)) return;
    const updatedOtp = [...otp];
    updatedOtp[index] = value.length <= 1 ? value : getNewChar(value, updatedOtp[index]);

    setOtp(updatedOtp);
    handleInputFocus(index, value);
  };

  const handleInputFocus = (index: number, value: string) => {
    if (index < otpTextInputs.length - 1 && value) otpTextInputs[index + 1].focus();
  };

  const onPaste = ({ clipboardData }: ClipboardEvent<HTMLInputElement>) => {
    const values = clipboardData.getData("text").split("");

    if (values.every(isValueValid)) {
      setOtp(values);
    }
  };

  const inputs = Array.from(Array(otpLength));
  const list = inputs.map((_, index) => (
    <OtpInputContainer
      key={index}
      index={index}
      value={otp[index]}
      handleChange={handleChange}
      handleNavigation={handleNavigation}
      onPaste={onPaste}
      isDisabled={isDisabled}
      theme={theme}
      inputRef={(ref) => (otpTextInputs[index] = ref as HTMLInputElement)}
    />
  ));

  return <div className="otpInputsContainer">{list}</div>;
};
