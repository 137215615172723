import { clone, unset } from "lodash";
import { gql } from "@apollo/client";
import { isDefined } from "@technis/shared";

export const DEFAULT_DEBOUNCE = 1000;

export const omitDeep = (value: any, keys: string[] | string) => {
  const clonedValue = clone(value);
  if (typeof clonedValue === "undefined") {
    return;
  }

  if (Array.isArray(clonedValue)) {
    for (let i = 0; i < clonedValue.length; i += 1) {
      clonedValue[i] = omitDeep(clonedValue[i], keys);
    }
    return clonedValue;
  }

  if (!isObject(clonedValue)) {
    return clonedValue;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return clonedValue;
  }

  for (let j = 0; j < keys.length; j += 1) {
    unset(clonedValue, keys[j]);
  }

  for (const key in clonedValue) {
    if (clonedValue.hasOwnProperty(key)) {
      clonedValue[key] = omitDeep(clonedValue[key], keys);
    }
  }

  return clonedValue;
};

export const isObject = (value: any) => value !== null && typeof value === "object";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefined = (object: any) => {
  if (object) {
    Object.keys(object).forEach((key) => {
      if (object[key] === undefined) {
        delete object[key];
      } else if (typeof object[key] === "object") {
        object[key] = removeUndefined(object[key]);
      }
    });
  }
  return object;
};

export const strToObject = (str: string) => {
  // eslint-disable-next-line quotes
  const parseStr = str.split("'").join('"');
  return JSON.parse(parseStr);
};

export const objectToStr = (obj: any) => {
  let stringifyObj = JSON.stringify(obj);
  // eslint-disable-next-line quotes
  stringifyObj = stringifyObj.split('"').join("'");
  return stringifyObj;
};

export const getStrAlias = (i: number) => `q${i}`;

export const mergeMultipleQueries = (allQueries: string[]) =>
  allQueries.length
    ? gql`
  {
    ${allQueries.map((q, i) => `${getStrAlias(i + 1)}: ${q}`)}
  }
`
    : gql``;

export const downloadJSON = (params: { fileName: string; jsonToDownload?: object }): Promise<void> =>
  new Promise((resolve, reject) => {
    try {
      const stringifiedJSON = JSON.stringify(replaceUndefinedWithNull(params.jsonToDownload), null, 3);
      const blob = new Blob([stringifiedJSON], { type: "application/json" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = params.fileName;
      a.click();
      URL.revokeObjectURL(downloadUrl);
      a.remove();
      resolve();
    } catch (error) {
      console.error("Error creating Blob:", error);
      reject(error);
    }
  });

export const replaceUndefinedWithNull = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Base case: If it's not an object or is null, return as is.
  }

  if (Array.isArray(obj)) {
    // If it's an array, iterate through the elements and recursively replace undefined values.
    return obj.map((item) => replaceUndefinedWithNull(item));
  }

  // If it's an object, iterate through its properties and recursively replace undefined values.
  const result: { [key: string]: any } = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value !== "undefined") {
        result[key] = replaceUndefinedWithNull(value);
      } else {
        result[key] = null;
      }
    }
  }
  return result;
};
