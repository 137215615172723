import { User, UserManager } from "oidc-client-ts";
import React, { useEffect } from "react";
import { AuthService, SSO_OPTIONS } from "@services/authService";
import { loginSuccess } from "@redux/auth/auth.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { displayToast } from "@redux/toast/toast.slice";
import { v4 as uuidv4 } from "uuid";
import { ComponentType } from "@technis/ui";

interface UserWithState extends User {
  state: { from: string };
}

export const SsoCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const signInCallback = async () => {
      new UserManager(SSO_OPTIONS).signinCallback().then(async (user) => {
        debugger;
        if (!user) {
          dispatch(
            displayToast({
              id: uuidv4(),
              text: "Failed to login, try again",
              variant: ComponentType.ERROR,
            }),
          );
          return;
        }
        const response = await AuthService.loginWithSsoToken(user.access_token);
        const token = response.data.loginWithSsoToken;

        dispatch(loginSuccess(token));
        navigate((user as UserWithState).state.from, { replace: true });
      });
    };
    signInCallback();
  }, [dispatch, navigate]);
  return <></>;
};
