import { useQuery } from "@apollo/client";
import { Icon, IconTypes, Text, Checkbox, Button, ComponentSize, ComponentType } from "@technis/ui";
import { OtpContactType, AuthErrors } from "@technis/shared";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { translation } from "@lang/translation";
import { i18n } from "@lang/i18n";
import { OtpInputs } from "./OtpInputs";
import { AuthService, LOGIN_WITH_OTP_TOKEN_QUERY, LoginWithOtpTokenQueryResult } from "@services/authService";
import { loginSuccess } from "@redux/auth/auth.slice";
import { BrowserTokenStorage } from "@utils/localStorage";

const OTP_LENGTH = 6;
const DELAY = 60;
const INTERVAL = 1000;

type LoginOtpFormProps = {
  otpContact: OtpContactType;
  goBack: () => void;
  resendCode: () => void;
  email?: string;
};

export const LoginOtpForm = ({ otpContact, goBack, resendCode, email = "" }: LoginOtpFormProps) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState<string[]>([]);
  const [trustBrowser, setTrustBrowser] = useState(false);
  const [timer, setTimer] = useState(0);
  const timerLabel = timer > 0 ? String(timer) : "";

  const { data, loading, error } = useQuery<LoginWithOtpTokenQueryResult>(LOGIN_WITH_OTP_TOKEN_QUERY, {
    variables: {
      otp: otp.join(""),
      trustBrowser,
    },
    skip: otp.join("").length !== OTP_LENGTH,
  });
  const errorMessage = error?.message && (error.message === AuthErrors.INVALID_OTP ? i18n.t(translation.loginOtp.invalidCode) : i18n.t(translation.login.commonError));

  const handleTrustBrowserChange = (value: ChangeEvent<HTMLInputElement>) => {
    setTrustBrowser(value.target.checked);
  };

  const handleResendCode = () => {
    setTimer(DELAY);
    resendCode();
  };

  useEffect(() => {
    const interval = timer > 0 && setInterval(() => setTimer(timer - 1), INTERVAL);

    return () => clearInterval(interval as ReturnType<typeof setInterval>);
  }, [timer]);

  useEffect(() => {
    if (data && data?.loginWithOtpToken) {
      if (trustBrowser) {
        AuthService.registerBrowser().then((res) => BrowserTokenStorage.set(email, res.data?.registerBrowser));
      }
      dispatch(loginSuccess(data.loginWithOtpToken));
    }
  }, [data, dispatch]);

  return (
    <div className="otpForm">
      <div className="otpFormHeader">
        <Icon size={20} icon={IconTypes.LOCK} />
        <Text className="otpTitle">{i18n.t(translation.loginOtp.title)}</Text>
      </div>
      <Text className="otpDescription">{i18n.t(translation.loginOtp.description, { otpContact })}</Text>
      <OtpInputs otp={otp} setOtp={setOtp} otpLength={OTP_LENGTH} isDisabled={loading} />
      {!!errorMessage && (
        <Text className="otpError" variant={ComponentType.ERROR}>
          {errorMessage}
        </Text>
      )}
      <Checkbox label={i18n.t(translation.loginOtp.dontAskAgain)} checked={trustBrowser} onChange={handleTrustBrowserChange} />
      <div className="buttonsContainer">
        <Button className="goBackButton" onClick={goBack} disabled={loading} size={ComponentSize.MEDIUM}>
          {i18n.t(translation.loginOtp.goBack)}
        </Button>
        <Button className="resendButton" onClick={handleResendCode} disabled={loading || timer > 0} size={ComponentSize.MEDIUM}>
          {timerLabel || i18n.t(translation.loginOtp.resend)}
        </Button>
      </div>
    </div>
  );
};
