import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  IconName,
  Modal,
  Size,
  DefaultInput,
  IconCommon,
  FlatButton,
  FilledButton,
  StepperTitle,
  ComponentType,
  FlatIconButton,
  TextV2,
  FontFamily,
  FontSize,
  FontWeight,
  useTheme,
  LegacyTopBar,
  MultiselectDropdown,
  MultiInputSelectedOption,
} from "@technis/ui";
import { ID, isEmailValid, Organization, RightType, UserLanguage, UserRoles } from "@technis/shared";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { AllOrganizationsQueryResult, ALL_ORGANIZATIONS_QUERY } from "@services/organizationService";
import { InvitationService } from "@services/invitationService";
import { displayToast } from "@redux/toast/toast.slice";
import { RightsView } from "./RightsView";
import { AccessesView } from "./AccessesView";
import { objectToStr } from "../../utils/utils";
import { TABS, supportedLanguages, getUserFormTabs } from "./common";

const emailInputsInitialState = [{ id: uuidv4(), value: "" }];

type InvitationEmailInputProps = {
  id: string;
  value: string;
  onChangeEmail: (id: string, value: string) => void;
  placeholder?: string;
  subtext?: string;
};

const InvitationEmailInput = ({ id, value, onChangeEmail, placeholder, subtext }: InvitationEmailInputProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChangeEmail(id, newValue);
  };

  return (
    <DefaultInput
      subText={subtext}
      className="form-input"
      width="100%"
      value={value}
      onChange={onChange}
      size={Size.MEDIUM}
      placeholder={placeholder}
      icon={<IconCommon name={IconName.ENVELOPE} size={Size.LARGE} />}
    />
  );
};

type InvitationModalProps = {
  isModalVisible: boolean;
  isBulkInvitation: boolean;
  onCloseModal: () => void;
};

export const InvitationModal = ({ isModalVisible, isBulkInvitation, onCloseModal }: InvitationModalProps) => {
  const dispatch = useDispatch();
  const themeColors = useTheme().theme.colors;
  const [selectedTabIndex, setSelectedTabIndex] = useState(TABS.GENERAL);
  const tabs = getUserFormTabs(setSelectedTabIndex);

  const { data } = useQuery<AllOrganizationsQueryResult>(ALL_ORGANIZATIONS_QUERY);
  const allOrganizations = data?.allOrganizations || [];

  const [selectedOrganization, setSelectedOrganization] = useState<Organization["id"] | null>(null);
  const [emailInputs, setEmailInputs] = useState(emailInputsInitialState);
  const [selectedLanguage, setSelectedLanguage] = useState<UserLanguage>(supportedLanguages[0].value);

  const [rightKeys, setRightKeys] = useState<string[]>([]);
  const [installationIds, setInstallationIds] = useState<ID[]>([]);

  const onOrganizationSelect = (option: MultiInputSelectedOption): void => {
    setSelectedOrganization(option.id as number);
  };

  const onLanguageSelect = (option: MultiInputSelectedOption): void => {
    setSelectedLanguage(option.id as UserLanguage);
  };

  const onChangeEmail = (id: string, value: string) => {
    setEmailInputs(emailInputs.map((input) => (input.id === id ? { ...input, value } : input)));
  };

  const onAddEmailInput = () => {
    setEmailInputs(emailInputs.concat({ id: uuidv4(), value: "" }));
  };

  const onClickNext = () => {
    if (selectedTabIndex === TABS.GENERAL) setSelectedTabIndex(TABS.RIGHTS);
    if (selectedTabIndex === TABS.RIGHTS) setSelectedTabIndex(TABS.ACCESSES);
  };

  const onClose = () => {
    onCloseModal();
    setSelectedTabIndex(TABS.GENERAL);
    setSelectedOrganization(null);
    setEmailInputs(emailInputsInitialState);
    setSelectedLanguage(supportedLanguages[0].value);
    setRightKeys([]);
    setInstallationIds([]);
  };

  const onClickSendInvitation = async () => {
    try {
      const organizationId = selectedOrganization;
      const rights = {
        organizationId,
        rightsRaw: objectToStr(
          Object.values(RightType).reduce((acc, key) => ({ ...acc, [key]: rightKeys.includes(key) || [RightType.SCHEDULE, RightType.REPORT].includes(key) }), {}),
        ),
      };
      const accesses = installationIds.map((installationId) => ({ installationId, interfaceId: null, eventIds: [], zoneIds: [] }));
      const invitations = emailInputs.map(({ value }) => ({
        organizationId,
        email: value,
        lang: selectedLanguage,
        role: UserRoles.USER,
        rights,
        accesses,
      }));

      for (const invitation of invitations) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await InvitationService.createInvitation(invitation); // TODO: fix InvitationInput type in shared
      }

      onClose();
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.createInvitationSuccessMessage), // TODO: add message for bulk invitation
          variant: ComponentType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.createInvitationErrorMessage),
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  const organizationOptions = useMemo(
    () =>
      allOrganizations.map((organization) => ({
        id: organization.id,
        caption: organization.name,
        onClick: () => setSelectedOrganization(organization.id),
      })),
    [allOrganizations],
  );

  const languageOptions = useMemo(
    () =>
      supportedLanguages.map((option) => ({
        id: option.value,
        caption: option.label,
        onClick: () => setSelectedLanguage(option.value),
      })),
    [],
  );

  const emailInputsMap = emailInputs.map(({ id, value }, index) => (
    <InvitationEmailInput key={id} id={id} value={value} onChangeEmail={onChangeEmail} subtext={`${i18n.t(translation.users.email)} ${index ? index + 1 : ""}`} />
  ));

  const isStepValid = () => {
    switch (selectedTabIndex) {
      case TABS.GENERAL:
      case TABS.ACCESSES:
        return selectedOrganization && emailInputs.every((emailInput) => isEmailValid(emailInput.value));
      case TABS.RIGHTS:
        return true;
    }
  };

  return (
    <Modal
      actionsElement={
        <div className="modal-footer">
          <FlatButton onClick={onClose} text={i18n.t(translation.users.cancel)} size={Size.LARGE} className="cancel-button" />
          {selectedTabIndex === TABS.ACCESSES ? (
            <FilledButton
              onClick={onClickSendInvitation}
              text={i18n.t(isBulkInvitation ? translation.users.sendInvitations : translation.users.sendInvitation)}
              size={Size.LARGE}
              disabled={!isStepValid()}
            />
          ) : (
            <FilledButton onClick={onClickNext} text={i18n.t(translation.users.next)} disabled={!isStepValid()} size={Size.LARGE} />
          )}
        </div>
      }
      headerElement={<StepperTitle title={i18n.t(translation.users.invitationModalTitle)} iconName={IconName.USER_GROUP} />}
      size={Size.LARGE}
      shown={isModalVisible}
      onClose={onClose}
    >
      <LegacyTopBar tabs={tabs} selectedTabIndex={selectedTabIndex} setSelectedItemIndex={setSelectedTabIndex} />
      {selectedTabIndex === TABS.GENERAL && (
        <div className="modal-tab-content">
          <div className="form-input-container">
            <MultiselectDropdown
              isSingleSelect
              subText={i18n.t(translation.users.organization)}
              options={organizationOptions}
              onSelectOption={onOrganizationSelect}
              selectedOptions={organizationOptions.filter((option) => option.id === selectedOrganization)}
            />
          </div>
          <div className="form-input-container">
            {emailInputsMap}
            <div className="emails-header">{isBulkInvitation && <FlatIconButton iconName={IconName.PLUS} iconSize={Size.MEDIUM} onClick={onAddEmailInput} />}</div>
          </div>
          <div className="form-input-container">
            <MultiselectDropdown
              isSingleSelect
              subText={i18n.t(translation.users.language)}
              options={languageOptions}
              onSelectOption={onLanguageSelect}
              selectedOptions={languageOptions.filter((option) => option.id === selectedLanguage)}
            />
          </div>
        </div>
      )}
      {selectedTabIndex === TABS.RIGHTS && (
        <div className="modal-tab-content">
          <RightsView rightKeys={rightKeys} setRightKeys={setRightKeys} />
        </div>
      )}
      {selectedTabIndex === TABS.ACCESSES && (
        <div className="modal-tab-content">
          <AccessesView installationIds={installationIds} setInstallationIds={setInstallationIds} {...(selectedOrganization && { organizationId: selectedOrganization })} />
        </div>
      )}
    </Modal>
  );
};
