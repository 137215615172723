import React, { useMemo, useState } from "react";
import { DefaultTable, IconName } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { useOrganizationsSearch } from "@utils/useOrganisationsSearch";
import { getOrganizationTableColumns, getOrganizationsRows } from "@utils/organizationsTableHelpers";
import { SearchWrapper } from "@components/searchHelpers";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE } from "@utils/utils";
import { OrganizationDataModal } from "./OrganizationDataModal";

export const OrganizationsSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isOrganizationDataModalVisible, setIsOrganizationDataModalVisible] = useState(false);
  const [organizationId, setOrganizationId] = useState<number>(0);

  const { organizations, refetchOrganizations, loading } = useOrganizationsSearch();

  const onChange = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        refetchOrganizations({ searchValue: event.target.value });
      }, DEFAULT_DEBOUNCE),
    [],
  );

  const onCloseOrganizationDataModal = () => {
    setIsOrganizationDataModalVisible(false);
  };

  const onClickOrganizationData = (OrganizationId: number) => {
    setOrganizationId(OrganizationId);
    setIsOrganizationDataModalVisible(true);
  };

  const organizationTableColumns = getOrganizationTableColumns(onClickOrganizationData);

  return (
    <SearchWrapper iconName={IconName.BUILDING} title={i18n.t(translation.search.organizations)} onChange={onChange} searchValue={searchValue}>
      {searchValue && !loading && (
        <DefaultTable columns={organizationTableColumns} rows={getOrganizationsRows(organizations, organizationTableColumns)} className="results-table" />
      )}
      <OrganizationDataModal isModalVisible={isOrganizationDataModalVisible} onCloseModal={onCloseOrganizationDataModal} organizationId={organizationId} />
    </SearchWrapper>
  );
};
