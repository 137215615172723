import React from "react";

const lightbeamSvg = (
  <svg fill="none" height="630" viewBox="0 0 600 630" width="600" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <clipPath id="a">
      <path d="m0 0h600v630h-600z" />
    </clipPath>
    <g clipPath="url(#a)">
      <rect fill="#05f" height="630" rx="8" width="600" />
      <rect fill="#1b67ff" height="544" rx="8" width="518" x="41" y="33" />
      <rect fill="#3176ff" height="448" rx="8" width="426" x="87" y="81" />
      <rect fill="#4f89ff" height="358" rx="8" width="340" x="130" y="126" />
      <rect fill="#75a3ff" height="276" rx="8" width="262" x="169" y="167" />
      <rect fill="#95b8ff" height="200" rx="8" width="190" x="205" y="205" />
      <rect fill="#b8d0ff" height="130" rx="8" width="124" x="238" y="230" />
      <rect fill="#dde8ff" height="76" rx="8" width="72" x="264" y="257" />
      <rect fill="#edf3ff" height="34" rx="8" width="32" x="284" y="275" />
    </g>
  </svg>
);

interface Props {
  show: boolean;
  onAnimationEnd: () => void;
}

export const Lightbeam = ({ show, onAnimationEnd }: Props) =>
  show ? (
    <div className="lightbeam" onAnimationEnd={onAnimationEnd}>
      {lightbeamSvg}
    </div>
  ) : (
    <></>
  );
