import { DashboardConfigurationJSON } from "@technis/shared";
import classNames from "classnames";
import React from "react";

interface Props {
  pages: DashboardConfigurationJSON["dashboard"]["pages"] | undefined;
  activePageIndex: number;
  onClick: (index: number) => void;
}

export const PreviewPageSwitcher = ({ pages, activePageIndex, onClick }: Props) => {
  if (!pages || pages.length < 2) return <></>;
  return (
    <ul className="preview-pages">
      {pages.map((page, i) => (
        <li key={i} onClick={() => onClick(i)} className={classNames({ active: activePageIndex === i })}>
          {page.name}
        </li>
      ))}
    </ul>
  );
};
