import React, { useState } from "react";
import { DefaultTable, IconName } from "@technis/ui";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { IconButton } from "@components/IconButton";
import { SearchBar } from "@components/SearchBar";
import { ROUTES } from "@routes/config";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { useUsersSearch } from "@utils/useUsersSearch";
import { getUserRows, getUsersTableColumns } from "@utils/usersTableHelpers";
import { EditUserModal } from "@views/UsersSearch/EditUserModal";
import { useQuery } from "@apollo/client";
import { USER_BY_ID_QUERY, UserByIdQueryResult } from "@services/userService";
import { DeleteUserModal } from "@views/UsersSearch/DeleteUserModal";
import { ViewAsModal } from "@views/UsersSearch/ViewAsModal";
import { User } from "@technis/shared";
import WidgetBuilderIcon from "../../components/svg/widget-builder.svg";
import { useGlobalSearch } from "./GlobalSearchContext";

export const GlobalSearch = () => {
  const navigate = useNavigate();
  const { setSearchValue, searchValue } = useGlobalSearch();
  const { users, refetchUsers } = useUsersSearch();

  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false);
  const [isViewAsModalVisible, setIsViewAsModalVisible] = useState(false);
  const [viewAsUserId, setViewAsUserId] = useState<User["id"]>("");
  const [userIdToUpdate, setUserIdToUpdate] = useState<string>("");
  const [userIdToDelete, setUserIdToToDelete] = useState<string>("");
  const { data } = useQuery<UserByIdQueryResult>(USER_BY_ID_QUERY, { variables: { userId: userIdToUpdate }, skip: !userIdToUpdate });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setSearchValue(newValue);
    refetchUsers({ searchValue: newValue });
  };

  const onNavigate = (url: string) => () => navigate(url);

  const onClickEdit = (userId: string) => {
    setIsEditUserModalVisible(true);
    setUserIdToUpdate(userId);
  };

  const onCloseEditUserModal = () => {
    setIsEditUserModalVisible(false);
    setUserIdToUpdate("");
  };

  const onClickDelete = (userId: string) => {
    setIsDeleteUserModalVisible(true);
    setUserIdToToDelete(userId);
  };

  const onCloseDeleteUserModal = () => {
    setIsDeleteUserModalVisible(false);
    setUserIdToToDelete("");
    refetchUsers({ searchValue });
  };

  const onSuccessDelete = () => {
    refetchUsers({ searchValue });
  };

  const onClickViewAsUser = async (userId: User["id"]) => {
    setIsViewAsModalVisible(true);
    setViewAsUserId(userId);
  };

  const onCloseViewAsUserModal = () => {
    setIsViewAsModalVisible(false);
    setViewAsUserId("");
  };

  const usersTableColumns = getUsersTableColumns(onClickEdit, onClickDelete, onClickViewAsUser);

  return (
    <div className={classNames("global-search", searchValue ? "global-search-content" : "global-search-home")}>
      <SearchBar searchValue={searchValue} onChange={onChange} />
      {searchValue ? (
        <>
          <DefaultTable columns={usersTableColumns} rows={getUserRows(users, usersTableColumns)} className="results-table" />
          {data && <EditUserModal user={data.userById} isModalVisible={isEditUserModalVisible} onCloseModal={onCloseEditUserModal} />}
          <DeleteUserModal userId={userIdToDelete} isModalVisible={isDeleteUserModalVisible} onCloseModal={onCloseDeleteUserModal} onSuccessDelete={onSuccessDelete} />
        </>
      ) : (
        <div className="buttons-container">
          <IconButton onClick={onNavigate(ROUTES.USERS)} iconName={IconName.USER_GROUP} label={i18n.t(translation.search.users)} />
          <IconButton onClick={onNavigate(ROUTES.INSTALLATIONS)} iconName={IconName.HOUSE} label={i18n.t(translation.search.installations)} />
          <IconButton onClick={onNavigate(ROUTES.ORGANIZATIONS)} iconName={IconName.BUILDING} label={i18n.t(translation.search.organizations)} />
          <IconButton onClick={onNavigate(ROUTES.WIDGET_BUILDER)} label={i18n.t(translation.search.widgetBuilder)} iconUrl={WidgetBuilderIcon} />
        </div>
      )}
      <ViewAsModal isModalVisible={isViewAsModalVisible} onCloseModal={onCloseViewAsUserModal} userId={viewAsUserId} />
    </div>
  );
};
