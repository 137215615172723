import { Access, ID } from "@technis/shared";
import React, { useEffect } from "react";
import { AccessesView } from "./AccessesView";

type UserAccessesViewProps = {
  allAccesses: Access[];
  installationIds: ID[];
  setInstallationIds: React.Dispatch<React.SetStateAction<ID[]>>;
};

export const UserAccessesView = ({ allAccesses, installationIds, setInstallationIds }: UserAccessesViewProps) => {
  useEffect(() => {
    if (allAccesses) {
      const userInstallationIds = allAccesses.map((access) => access.installationId);
      setInstallationIds(userInstallationIds);
    }
  }, [allAccesses, setInstallationIds]);

  return <AccessesView installationIds={installationIds} setInstallationIds={setInstallationIds} />;
};
