import React, { FC, useCallback, useEffect, useRef } from "react";
import ace from "brace";
import JSONEditor, { JSONEditorOptions } from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import "brace/mode/json";
import "brace/theme/chrome";

import schema from "./schema.json";
import { debounce } from "lodash";

export enum EditorCommand {
  ReloadPreview = "RELOAD_PREVIEW",
  Save = "SAVE",
}

type Props = {
  value: object;
  onChange: (value: object, updatePreview: boolean) => void;
};

export const WidgetJsonEditor: FC<Props> = ({ value = {}, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editor = useRef<JSONEditor | null>(null);

  const handleChange = useCallback(
    debounce(
      (textJson: string) => {
        try {
          onChange && onChange(JSON.parse(textJson), false);
        } catch {}
      },
      500,
      { trailing: true },
    ),
    [onChange],
  );
  const handleCommand = useCallback(
    (cmd: EditorCommand, textJson: string) => {
      try {
        if (cmd === EditorCommand.ReloadPreview) {
          onChange && onChange(JSON.parse(textJson), true);
        }
      } catch {}
    },
    [onChange],
  );

  useEffect(() => {
    if (!containerRef.current || !editor.current) {
      return;
    }

    editor.current.update(value);
  }, [value]);

  useEffect(() => editor.current?.destroy(), []);

  useEffect(() => {
    if (containerRef.current && !editor.current) {
      editor.current = new JSONEditor(
        containerRef.current,
        {
          schema,
          schemaRefs: schema.definitions,
          ace: ace as unknown as JSONEditorOptions["ace"],
          allowSchemaSuggestions: true,
          theme: "ace/theme/chrome",
          mode: "code",
          onChangeText: handleChange,
          colorPicker: true,
        },
        value,
      );
      editor.current.aceEditor.setFontSize("12px");
      editor.current.aceEditor.commands.addCommands([
        {
          bindKey: {
            win: "ctrl+enter",
            mac: "cmd+enter",
          },
          exec: (editor) => handleCommand(EditorCommand.ReloadPreview, editor.getValue()),
        },
      ]);
    }
  }, [containerRef, value, handleChange]);

  return <div ref={containerRef} />;
};
