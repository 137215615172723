import { useLazyQuery } from "@apollo/client";
import { ORGANIZATION_CONNECTION_QUERY, OrganizationConnectionQueryResult } from "@services/organizationService";

export const useOrganizationsSearch = () => {
  const [getOrganizations, { data, loading }] = useLazyQuery<OrganizationConnectionQueryResult>(ORGANIZATION_CONNECTION_QUERY);

  const edges =
    data?.organizationConnection.edges.map((edge) => ({
      name: `${edge.node.name} (${edge.node.id})`,
      address: edge.node.address || "-",
      creationDate: edge.node.creationDate,
      organizationId: edge.node.id,
    })) || [];

  return {
    organizations: edges,
    loading,
    refetchOrganizations: ({ searchValue = "" }: { searchValue?: string }) => {
      getOrganizations({
        variables: {
          searchValue,
        },
      });
    },
  };
};
