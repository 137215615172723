import { useQuery, useLazyQuery } from "@apollo/client";
import { AllOrganizationsQueryResult, ALL_ORGANIZATIONS_QUERY } from "@services/organizationService";
import { INSTALLATION_CONNECTION_QUERY, InstallationConnectionQueryResult } from "@services/installationService";
import { keyBy } from "lodash";

export const useInstallationsSearch = () => {
  const { data: allOrganizationsData, loading } = useQuery<AllOrganizationsQueryResult>(ALL_ORGANIZATIONS_QUERY);
  const [getInstallations, { data, loading: fetchLoading }] = useLazyQuery<InstallationConnectionQueryResult>(INSTALLATION_CONNECTION_QUERY);

  const organizationMap = keyBy(allOrganizationsData?.allOrganizations ?? [], "id");
  const edges =
    data?.installationConnection.edges.map((edge) => ({
      name: `${edge.node.name} (${edge.node.id})`,
      address: edge.node.address,
      installationId: edge.node.id,
      organization: `${organizationMap[edge.node.organizationId]?.name} (${edge.node.organizationId})`,
      creationDate: edge.node.creationDate,
    })) || [];

  return {
    installations: edges,
    loading,
    fetchLoading,
    refetchInstallations: ({ searchValue = "" }: { searchValue?: string }) => {
      getInstallations({
        variables: {
          searchValue,
        },
      });
    },
  };
};
