/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Access, ID, isEmailValid, Organization, OtpContactType, RightType, User, UserRoles } from "@technis/shared";
import { useQuery } from "@apollo/client";
import React, { useState, useEffect, useMemo } from "react";
import {
  IconName,
  Modal,
  Size,
  DefaultDropdownInput,
  DefaultInput,
  IconCommon,
  FlatButton,
  FilledButton,
  PhoneInputWithCountryCodes,
  Loader,
  SimpleDropdown,
  StepperTitle,
  Switch,
  ComponentType,
  PhoneChangeEventArgs,
  useTheme,
  TextV2,
  FontFamily,
  FontSize,
  FontWeight,
  LegacyTopBar,
} from "@technis/ui";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { UserRightsView } from "./UserRightsView";
import { UserAccessesView } from "./UserAccessesView";
import { AllOrganizationsQueryResult, ALL_ORGANIZATIONS_QUERY } from "../../services/organizationService";
import { TABS, getUserFormTabs, LanguageDropdownOption, supportedLanguages, ThemeDropdownOption, themeDropdownOptions, getOtpContactsForUser } from "./common";
import { objectToStr } from "@utils/utils";
import { AccessService } from "@services/accessService";
import { UserService } from "@services/userService";
import { displayToast } from "@redux/toast/toast.slice";

type EditUserModalProps = {
  user: User;
  isModalVisible: boolean;
  onCloseModal: () => void;
};

export const EditUserModal = ({ user, isModalVisible, onCloseModal }: EditUserModalProps) => {
  const dispatch = useDispatch();
  const themeColors = useTheme().theme.colors;
  const { data: allOrganizationsData, loading } = useQuery<AllOrganizationsQueryResult>(ALL_ORGANIZATIONS_QUERY);
  const allOrganizations = useMemo(() => allOrganizationsData?.allOrganizations || [], [allOrganizationsData?.allOrganizations]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(TABS.GENERAL);
  const tabs = getUserFormTabs(setSelectedTabIndex);

  const [allAccesses, setAllAccesses] = useState<Access[]>([]);
  const [userData, setUserData] = useState<User>(user);
  const [rightKeys, setRightKeys] = useState<string[]>([]);
  const [installationIds, setInstallationIds] = useState<ID[]>([]);
  const [countryName, setCountryName] = useState("Poland");
  const isAdmin = userData.role === UserRoles.ADMIN;

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setUserData({ ...userData, name: newValue });
  };
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setUserData({ ...userData, email: newValue });
  };
  const handlePhoneChange = ({ inputValue }: PhoneChangeEventArgs) => {
    setUserData({ ...userData, phone: inputValue });
  };
  const handleOnCountryChange = (inputValue: string) => {
    setCountryName(inputValue);
  };

  console.log("countryName", countryName);
  console.log("userData", userData.phone);

  const [isOrganizationDropdownOpen, setIsOrganizationDropdownOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Pick<Organization, "id" | "name">>();
  const handleOrganizationDropdownTriggerClick = (isOpen: boolean) => {
    setIsOrganizationDropdownOpen(isOpen);
  };
  const handleOrganizationDropdownClick = (organization: Pick<Organization, "id" | "name">) => {
    setSelectedOrganization(organization);
    setUserData({ ...userData, organizationIds: [organization.id] });
  };
  const organizationOptions = allOrganizations.map((organization) => ({
    caption: organization.name,
    onClick: () => handleOrganizationDropdownClick(organization),
  }));

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageDropdownOption>();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const handleLanguageDropdownClick = (option: LanguageDropdownOption) => {
    setSelectedLanguage(option);
    setUserData({ ...userData, lang: option.value });
  };
  const handleLanguageDropdownTriggerClick = (isOpen: boolean) => {
    setIsLanguageDropdownOpen(isOpen);
  };
  const languageOptions = supportedLanguages.map((option) => ({
    caption: option.label,
    onClick: () => handleLanguageDropdownClick(option),
  }));

  const [selectedTheme, setSelectedTheme] = useState<ThemeDropdownOption>();
  const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);
  const handleThemeDropdownClick = (option: ThemeDropdownOption) => {
    setSelectedTheme(option);
    setUserData({ ...userData, theme: option.value });
  };
  const handleThemeDropdownTriggerClick = (isOpen: boolean) => {
    setIsThemeDropdownOpen(isOpen);
  };
  const themeOptions = themeDropdownOptions.map((option) => ({ caption: option.label, onClick: () => handleThemeDropdownClick(option) }));

  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(!!user.otpContact);
  const [isOtpContactDropdownOpen, setIsOtpContactDropdownOpen] = useState(false);
  const handleOtpContactDropdownClick = (otpContact: OtpContactType) => {
    setUserData({ ...userData, otpContact });
  };
  const handleOtpContactDropdownTriggerClick = (isOpen: boolean) => {
    setIsOtpContactDropdownOpen(isOpen);
  };
  const otpContactOptions = getOtpContactsForUser({ isEmailVerified: userData?.isEmailVerified, isPhoneVerified: userData?.isPhoneVerified }).map((contact) => ({
    caption: i18n.t(translation.users[contact]),
    onClick: () => handleOtpContactDropdownClick(contact),
  }));
  const onChangeTwoFA = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsTwoFaEnabled(isChecked);
    isChecked && !userData.otpContact && setUserData({ ...userData, otpContact: OtpContactType.EMAIL });
  };

  useEffect(() => {
    const { organizationIds, lang, theme: userTheme } = userData;
    setSelectedOrganization(allOrganizations.find((org) => organizationIds.includes(org.id)));
    setSelectedLanguage(supportedLanguages.find((language) => language.value === lang));
    setSelectedTheme(themeDropdownOptions.find((theme) => theme.value === userTheme));
  }, [allOrganizations, userData]);

  const onClose = () => {
    setSelectedTabIndex(TABS.GENERAL);
    onCloseModal();
  };

  const onClickSave = async () => {
    try {
      if (isEmailValid(userData.email)) {
        const { otpContact, isEmailVerified, isPhoneVerified, ...user } = userData; // TODO: allow to update otpContact on BE
        const [organizationId] = userData.organizationIds;
        const updatedRights = [
          {
            organizationId,
            rightsRaw: objectToStr(Object.values(RightType).reduce((acc, key) => ({ ...acc, [key]: rightKeys.includes(key) }), {})),
          },
        ];
        const updatedAccesses = installationIds.map((installationId) => {
          const existingAccess = allAccesses.find((access) => access.installationId === installationId);
          if (existingAccess) {
            return existingAccess;
          } else {
            return {
              installationId,
              interfaceId: 0,
              eventIds: [],
              zoneIds: [],
            };
          }
        });
        const userInput = {
          ...user,
          rights: updatedRights,
          accesses: updatedAccesses,
        };
        // @ts-ignore
        await UserService.mutateUser(userInput);
        onClose();
        dispatch(
          displayToast({
            id: uuidv4(),
            text: i18n.t(translation.users.mutateUserSuccessMessage), // TODO: fix message
            variant: ComponentType.SUCCESS,
          }),
        );
      }
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: i18n.t(translation.users.mutateUserErrorMessage), // TODO: fix error message
          variant: ComponentType.ERROR,
        }),
      );
    }
  };

  const onClickNext = async () => {
    if (selectedTabIndex === TABS.GENERAL) setSelectedTabIndex(TABS.RIGHTS);
    if (selectedTabIndex === TABS.RIGHTS) {
      setSelectedTabIndex(TABS.ACCESSES);
      if (userData.accessIds.length) {
        const accessesQueryResult = await AccessService.accessByIds(userData.accessIds);
        const allAccesses = accessesQueryResult.data ? Object.values(accessesQueryResult.data) : [];
        setAllAccesses(allAccesses);
      }
    }
  };

  const isValidUser = userData.name && isEmailValid(userData.email);

  return (
    // @ts-ignore // TODO: add children to ModalProps interface in technis-ui
    <Modal
      actionsElement={
        <div className="modal-footer">
          <FlatButton onClick={onClose} text={i18n.t(translation.users.cancel)} size={Size.LARGE} className="cancel-button" />
          {isAdmin || selectedTabIndex === TABS.ACCESSES ? (
            <FilledButton onClick={onClickSave} text={i18n.t(translation.users.save)} size={Size.LARGE} disabled={!isValidUser} />
          ) : (
            <FilledButton onClick={onClickNext} text={i18n.t(translation.users.next)} size={Size.LARGE} />
          )}
        </div>
      }
      headerElement={<StepperTitle title={i18n.t(translation.users.user)} iconName={IconName.USER_GROUP} />}
      size={Size.LARGE}
      shown={isModalVisible}
      onClose={onClose}
    >
      {loading || !userData ? (
        <Loader />
      ) : (
        <>
          {!isAdmin && <LegacyTopBar tabs={tabs} selectedTabIndex={selectedTabIndex} setSelectedItemIndex={setSelectedTabIndex} />}
          {selectedTabIndex === TABS.GENERAL && (
            <div className="modal-tab-content">
              <div className="form-input-container">
                <DefaultInput className="form-input" subText={i18n.t(translation.users.accountName)} value={userData.name} onChange={onChangeName} />
              </div>
              <div className="form-input-container">
                <DefaultInput
                  className="form-input"
                  subText={i18n.t(translation.users.email)}
                  value={userData.email}
                  onChange={onChangeEmail}
                  icon={<IconCommon name={IconName.ENVELOPE} size={Size.LARGE} />}
                />
              </div>
              <div className="form-input-container">
                <TextV2 color={themeColors.OLD_BASE_40} fontFamily={FontFamily.LATO} fontSize={FontSize.SM} fontWeight={FontWeight.SEMIBOLD}>
                  {i18n.t(translation.users.telephone)}
                </TextV2>
                {/* eslint-disable-next-line react/jsx-no-comment-textnodes*/}
                {/* @ts-ignore */}
                <PhoneInputWithCountryCodes
                  dropdownHeight="20rem"
                  countryName={countryName}
                  handleOnCountryChange={handleOnCountryChange}
                  handlePhoneChange={handlePhoneChange}
                  value={userData.phone || ""}
                />
              </div>
              <div className="form-input-container">
                <SimpleDropdown
                  options={organizationOptions}
                  triggerElement={
                    <DefaultDropdownInput
                      className="form-input"
                      label={i18n.t(translation.users.organization)}
                      placeholder={i18n.t(translation.users.organization)}
                      isOpen={isOrganizationDropdownOpen}
                      selectedOption={selectedOrganization?.name || ""}
                    />
                  }
                  onTriggerClick={handleOrganizationDropdownTriggerClick}
                />
              </div>
              <div className="form-input-container">
                <SimpleDropdown
                  options={languageOptions}
                  triggerElement={
                    <DefaultDropdownInput
                      className="form-input"
                      label={i18n.t(translation.users.language)}
                      isOpen={isLanguageDropdownOpen}
                      selectedOption={selectedLanguage?.label || ""}
                    />
                  }
                  onTriggerClick={handleLanguageDropdownTriggerClick}
                />
              </div>
              <div className="form-input-container">
                <SimpleDropdown
                  options={themeOptions}
                  triggerElement={
                    <DefaultDropdownInput className="form-input" label={i18n.t(translation.users.theme)} isOpen={isThemeDropdownOpen} selectedOption={selectedTheme?.label || ""} />
                  }
                  onTriggerClick={handleThemeDropdownTriggerClick}
                />
              </div>
              <Switch id="two-fa-switch-id" name="two-fa-switch-name" label={i18n.t(translation.users.twoFA)} checked={isTwoFaEnabled} onChange={onChangeTwoFA} />
              {isTwoFaEnabled && otpContactOptions && (
                <div className="form-input-container">
                  <SimpleDropdown
                    options={otpContactOptions}
                    triggerElement={
                      <DefaultDropdownInput
                        className="form-input"
                        label={i18n.t(translation.users.twoFAVerification)}
                        isOpen={isOtpContactDropdownOpen}
                        selectedOption={i18n.t(translation.users[userData.otpContact])}
                      />
                    }
                    onTriggerClick={handleOtpContactDropdownTriggerClick}
                  />
                </div>
              )}
            </div>
          )}
          {selectedTabIndex === TABS.RIGHTS && (
            <div className="modal-tab-content">
              <UserRightsView rightIds={userData.rightIds} rightKeys={rightKeys} setRightKeys={setRightKeys} />
            </div>
          )}
          {selectedTabIndex === TABS.ACCESSES && (
            <div className="modal-tab-content">
              <UserAccessesView allAccesses={allAccesses} installationIds={installationIds} setInstallationIds={setInstallationIds} />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
