const saveToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

const getFromLocalStorage = (key: string) => localStorage.getItem(key);

const BROWSER_ID_KEY = "browserId";
const makeBrowserIdKey = (email: string) => `${BROWSER_ID_KEY}_${email}`;
export const BrowserTokenStorage = {
  get: (email: string) => getFromLocalStorage(makeBrowserIdKey(email)),
  set: (email: string, browserToken: string) => saveToLocalStorage(makeBrowserIdKey(email), browserToken),
  del: (email: string) => removeFromLocalStorage(makeBrowserIdKey(email)),
};
