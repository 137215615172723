import { Dispatch } from "@reduxjs/toolkit";
import { Button, ComponentSize, Heading } from "@technis/ui";
import React, { ErrorInfo, ReactNode, Component } from "react";
import { connect } from "react-redux";

import { translate } from "../lang/i18n";
import { logout } from "../redux/auth/auth.slice";

interface OwnProps {
  children?: ReactNode;
}

interface DispatchProps {
  logout(): void;
}

interface State {
  hasError: boolean;
  isLoggingOut: boolean;
}

type Props = OwnProps & DispatchProps;

class BaseErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, isLoggingOut: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  reload = () => window.location.reload();

  logout = () => {
    this.setState({ isLoggingOut: true }, () => {
      this.props.logout();
      window.location.href = "/";
    });
  };

  render() {
    const { isLoggingOut, hasError } = this.state;

    if (hasError) {
      return (
        <div className="errorBoundary">
          <Heading size={ComponentSize.LARGE}>{translate({ key: "errors.unHandled" })}</Heading>

          <div className="errorBoundary--buttons">
            <Button onClick={this.reload} size={ComponentSize.MEDIUM}>
              {translate({ key: "common.reload" })}
            </Button>

            <Button onClick={this.logout} size={ComponentSize.MEDIUM} isLoading={isLoggingOut} disabled={isLoggingOut}>
              {translate({ key: "common.logout" })}
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(logout()),
});

export const ErrorBoundary: any = connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps)(BaseErrorBoundary);
