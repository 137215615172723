import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { ID, Installation } from "@technis/shared";
import { useTheme, Size, IconCommon, IconName, Theme, MultiselectDropdown, MultiInputSelectedOption, FilledButton, Variant } from "@technis/ui";
import { AllInstallationsQueryResult, ALL_INSTALLATIONS_QUERY } from "@services/installationService";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

type AccessesViewProps = {
  organizationId?: number;
  installationIds: ID[];
  setInstallationIds: React.Dispatch<React.SetStateAction<ID[]>>;
};

export const AccessesView = ({ installationIds, setInstallationIds, organizationId }: AccessesViewProps) => {
  const { theme } = useTheme();
  const { data } = useQuery<AllInstallationsQueryResult>(ALL_INSTALLATIONS_QUERY);
  const allInstallations = data?.allInstallations || [];

  const onOptionClick = (installationId: ID) => {
    setInstallationIds([...installationIds, installationId]);
  };

  const onRemoveInstallation = (installationId: ID) => {
    setInstallationIds(installationIds.filter((item) => item !== installationId));
  };

  // const toggleInstallation = ()s

  const dropdownOptions = useMemo(
    () =>
      allInstallations
        .filter((installation) => /* !installationIds.includes(installation.id) && */ !organizationId || installation.organizationId === organizationId)
        .map((installation) => ({ id: installation.id, caption: installation.name, onClick: () => alert(installation.id) })),
    [allInstallations, installationIds, organizationId],
  );

  const onSelectOption = (option: MultiInputSelectedOption): void => {
    onOptionClick(option.id as number);
  };

  const onRemoveOption = (option: MultiInputSelectedOption): void => {
    onRemoveInstallation(option.id as number);
  };

  const selectAll = () => {
    setInstallationIds([...installationIds, ...dropdownOptions.map((option) => option.id as number)]);
  };

  const deselectAll = () => {
    setInstallationIds([]);
  };

  return (
    <>
      <div className="access-actions">
        <FilledButton onClick={selectAll} text={i18n.t("installation.selectAll")} size={Size.SMALL} />
        {!!installationIds.length && <FilledButton onClick={deselectAll} text={i18n.t("installation.unselectAll")} size={Size.SMALL} colorVariant={Variant.SECONDARY} />}
      </div>

      <div className="form-input-container">
        <MultiselectDropdown
          subText={i18n.t(translation.users.installations)}
          options={dropdownOptions}
          onSelectOption={onSelectOption}
          onRemoveOption={onRemoveOption}
          selectedOptions={dropdownOptions.filter((option) => installationIds.includes(option.id))}
        />
      </div>
    </>
  );
};
