import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { IconName, Modal, Size, FilledButton, StepperTitle, ComponentType, FontSize, useTheme, CodeBlock } from "@technis/ui";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { displayToast } from "@redux/toast/toast.slice";

import { UserData } from "@services/userService";
import { GET_ORGANIZATION_INFO_QUERY, GetOrganizationDataQueryResult } from "@services/organizationService";
import { downloadJSON, replaceUndefinedWithNull } from "@utils/utils";

const FILE_NANE_PREFIX = "organizationData-";

type OrganizationDataModalProps = {
  isModalVisible: boolean;
  onCloseModal: () => void;
  organizationId: number;
  user?: UserData;
};

export const OrganizationDataModal = ({ organizationId, isModalVisible, onCloseModal }: OrganizationDataModalProps) => {
  const dispatch = useDispatch();
  const themeColors = useTheme().theme.colors;

  const { data } = useQuery<GetOrganizationDataQueryResult>(GET_ORGANIZATION_INFO_QUERY, { variables: { organizationId } });
  const jsonToDownload = data?.getOrganizationInfo;
  const [organizationJSON, setOrganizationJSON] = useState("");
  const fileName = `${FILE_NANE_PREFIX}${organizationId}`;

  useEffect(() => {
    if (data) {
      setOrganizationJSON(JSON.stringify(replaceUndefinedWithNull(jsonToDownload), null, 3));
    }
  }, [data]);

  const onClose = () => {
    onCloseModal();
    setOrganizationJSON("");
  };

  const onClickSend = async () => {
    downloadJSON({ jsonToDownload, fileName })
      .then(() => {
        onClose();
        dispatch(
          displayToast({
            id: uuidv4(),
            text: i18n.t(translation.common.downloadSuccessMessage),
            variant: ComponentType.SUCCESS,
          }),
        );
      })
      .catch((error) => {
        console.log("Couldnt download json: ", error);
        dispatch(
          displayToast({
            id: uuidv4(),
            text: i18n.t(translation.common.downloadErrorMessage),
            variant: ComponentType.ERROR,
          }),
        );
      });
  };

  return (
    <Modal
      actionsElement={
        <div className="support-data-modal-footer">
          <FilledButton onClick={onClickSend} text={i18n.t(translation.common.download)} size={Size.LARGE} disabled={!organizationJSON} />
        </div>
      }
      headerElement={<StepperTitle title={i18n.t(translation.search.organizationData)} iconName={IconName.ENVELOPE} />}
      size={Size.EXTRA_LARGE}
      shown={isModalVisible}
      onClose={onClose}
    >
      <div className="code-block-container">
        <CodeBlock color={themeColors.OLD_BASE_40} fontSize={FontSize.SM} borderColor={themeColors.OLD_BASE_40}>
          {organizationJSON}
        </CodeBlock>
      </div>
    </Modal>
  );
};
