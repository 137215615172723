import React from "react";
import { FilledButton, Modal, Size, SubTitle, Variant } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { Dashboards, useViewAsUser } from "@utils/useViewAsUser";

type ViewAsModalProps = {
  userId: string;
  isModalVisible: boolean;
  onCloseModal: () => void;
};

export const ViewAsModal = ({ userId, isModalVisible, onCloseModal }: ViewAsModalProps) => {
  const { viewAsUser: viewAsUserLegacy } = useViewAsUser(Dashboards.LEGACY);
  const { viewAsUser: viewAsUserRetail } = useViewAsUser(Dashboards.VISION);

  const onClose = () => {
    onCloseModal();
  };

  const onClickViewAsLegacy = async () => {
    await viewAsUserLegacy(userId);
  };

  const onClickViewAsRetail = async () => {
    await viewAsUserRetail(userId);
  };

  return (
    <Modal
      actionsElement={
        <div className="modal-footer">
          <FilledButton
            onClick={onClickViewAsLegacy}
            text={i18n.t(translation.search.viewAsOptions.legacy)}
            colorVariant={Variant.PRIMARY}
            size={Size.LARGE}
            className="extra-button"
          />
          <FilledButton onClick={onClickViewAsRetail} text={i18n.t(translation.search.viewAsOptions.retail)} colorVariant={Variant.ACCENT} size={Size.LARGE} />
        </div>
      }
      size={Size.MEDIUM}
      shown={isModalVisible}
      onClose={onClose}
    >
      <SubTitle text={i18n.t(translation.search.selectViewAsOption)} size={Size.MEDIUM} />
    </Modal>
  );
};
