import React from "react";
import { IconName, SortingType, FlatButton, Variant, TableColumnV2 } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { DateFormats, Organization, isDefined } from "@technis/shared";
import { get, isNumber } from "lodash";
import moment from "moment";

export const getOrganizationTableColumns = (onClickOrganizationData: (organizationId: number) => void): TableColumnV2[] => [
  {
    key: "name",
    header: i18n.t(translation.search.organization),
    type: SortingType.STRING,
  },
  {
    key: "address",
    header: i18n.t(translation.search.address),
    type: SortingType.STRING,
  },
  {
    key: "creationDate",
    header: i18n.t(translation.search.creationDate),
    type: SortingType.STRING,
    render: (creationDate) => {
      if (isNumber(creationDate)) {
        if (!isDefined(creationDate)) return "-";
        return moment(creationDate).format(DateFormats.UNIVERSAL);
      }
    },
  },
  {
    key: "organizationId",
    header: i18n.t(translation.search.actions),
    sortable: false,
    render: (organizationId) => {
      if (isNumber(organizationId))
        return (
          <div className="actions">
            <FlatButton
              iconName={IconName.FILE_CHART_COLUMN}
              text={i18n.t(translation.search.organizationData)}
              colorVariant={Variant.ACCENT}
              onClick={() => onClickOrganizationData(organizationId)}
            />
          </div>
        );
    },
  },
];

export const getOrganizationsRows = (organizations: Partial<Organization>[], columns: ReturnType<typeof getOrganizationTableColumns>) => {
  const keys = columns.map(({ key }) => key);

  return organizations.map((organization) => ({
    rows: keys.reduce((acc, key) => ({ ...acc, [key]: get(organization, key, "") }), {}),
  }));
};
