import { gql, useQuery } from "@apollo/client";
import { FilledButton, Loader, Modal, Size, Variant } from "@technis/ui";
import React, { useState } from "react";
import { keyBy } from "lodash";
import { Dashboard, DashboardConfigurationJSON } from "@technis/shared";
import { useTranslation } from "react-i18next";
import { translation } from "@lang/translation";
import { DashboardService } from "@services/dashboardService";

const allDashboardsWithOrgsQuery = gql`
  query {
    allCustomDashboards {
      id
      organizationId
      name
    }
    allOrganizations {
      id
      name
    }
  }
`;

interface SelectOption {
  id: string;
  label: string;
}

interface Props {
  onDashboardSelected: (dashboard: Partial<DashboardConfigurationJSON>) => void;
}

export const DashboardSelector = ({ onDashboardSelected }: Props) => {
  const { t } = useTranslation();

  const [isModalShown, setIsModalShown] = useState(false);
  const showModal = () => setIsModalShown(true);
  const hideModal = () => {
    setIsModalShown(false);
    setSelectedDashboardId(-1);
  };
  const { data, loading } = useQuery(allDashboardsWithOrgsQuery, { skip: !isModalShown });
  const orgIndex = keyBy(data?.allOrganizations || [], "id");
  const options: [SelectOption] = (data?.allCustomDashboards || []).map((dash: Dashboard) => ({
    id: dash.id,
    label: `${dash.name} (${dash.organizationId ? orgIndex[dash.organizationId]?.name : "N/A"})`,
  }));
  const [selectedDashboardId, setSelectedDashboardId] = useState<number>(data?.allCustomDashboards[0].id || -1);
  const onDashboardIdChange = async (event: React.FormEvent<HTMLSelectElement>) => {
    setSelectedDashboardId(parseInt(event.currentTarget.value, 10));
  };
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const onConfirm = async () => {
    if (selectedDashboardId === -1) return;

    setIsLoadingConfig(true);
    try {
      const config = await DashboardService.getDashboard(selectedDashboardId);
      onDashboardSelected(config);
      hideModal();
      setSelectedDashboardId(-1);
    } finally {
      setIsLoadingConfig(false);
    }
  };

  return (
    <>
      <FilledButton onClick={showModal} disabled={loading} colorVariant={Variant.WARN}>
        {t(translation.widgetBuilder.load)}
      </FilledButton>
      <Modal shown={isModalShown} size={Size.MEDIUM} onClose={hideModal}>
        <h2>Choose a dashboard to open</h2>

        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Loader size={Size.MEDIUM} />
          </div>
        ) : (
          <select style={{ width: "100%", padding: "8px", borderColor: "#c0c0c0", borderRadius: "6px" }} value={selectedDashboardId} onChange={onDashboardIdChange}>
            <option></option>
            {options.map((opt) => (
              <option value={opt.id} key={opt.id}>
                {opt.label}
              </option>
            ))}
          </select>
        )}

        <br />
        <br />
        <FilledButton onClick={onConfirm} loading={isLoadingConfig} disabled={loading} width="100%">
          {t(translation.widgetBuilder.load)}
        </FilledButton>
      </Modal>
    </>
  );
};
