/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from "@apollo/client";
import { Loader } from "@technis/ui";
import { Right } from "@technis/shared";
import React, { SetStateAction, useEffect } from "react";
import { queryRightById } from "@services/rightService";
import { RightsView } from "./RightsView";
import { strToObject, mergeMultipleQueries } from "../../utils/utils";

export const mergeRight = (rights: Right[]) => {
  if (rights.length === 0) return {};
  if (rights.length === 1) return rights[0].rightsRaw;

  // check bigger right
  let biggestRight: Right;
  rights.forEach((right) => {
    if (!biggestRight) biggestRight = right;
    if (Object.keys(right.rightsRaw).length > Object.keys(biggestRight.rightsRaw).length) {
      biggestRight = right;
    }
  });

  // @ts-ignore
  const rightsName = Object.keys(biggestRight.rightsRaw);
  const mergeRightOutput = {};

  rights.forEach((right) => {
    rightsName.forEach((rightName) => {
      // @ts-ignore
      if (!mergeRightOutput[rightName]) mergeRightOutput[rightName] = right.rightsRaw[rightName];
      // @ts-ignore
      mergeRightOutput[rightName] = mergeRightOutput[rightName] || right.rightsRaw[rightName];
    });
  });

  return mergeRightOutput;
};

const getKeysFromUserRights = (allRights: Right[]) => {
  const formattedRights = allRights.map((right) => ({
    ...right,
    rightsRaw: strToObject(right.rightsRaw),
  }));
  const mergedRights = mergeRight(formattedRights);
  const rightKeys = Object.entries(mergedRights).reduce((keys, pair) => {
    const [key, value] = pair;
    if (value) {
      keys.push(key);
    }
    return keys;
  }, [] as string[]);

  return rightKeys;
};

interface RightsQueryResult {
  [key: string]: Right;
}

type UserRightsViewProps = {
  rightIds: number[];
  rightKeys: string[];
  setRightKeys: React.Dispatch<SetStateAction<string[]>>;
};

export const UserRightsView = ({ rightIds, rightKeys, setRightKeys }: UserRightsViewProps) => {
  const allQueries = rightIds.map((rightId) => queryRightById(rightId));
  const query = mergeMultipleQueries(allQueries);
  const { data, loading } = useQuery<RightsQueryResult>(query, { skip: !rightIds });

  useEffect(() => {
    if (data) {
      const keys = getKeysFromUserRights(Object.values(data));
      setRightKeys(keys);
    }
  }, [data, setRightKeys]);

  if (loading) {
    return <Loader />;
  }

  return <RightsView rightKeys={rightKeys} setRightKeys={setRightKeys} />;
};
