/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState } from "react";

const GlobalSearchContext = createContext<{
  searchValue: string;
  setSearchValue: (value: string) => void;
  resetSearch: () => void;
}>({
  searchValue: "",
  setSearchValue: (_value: string) => {},
  resetSearch: () => {},
});

export const GlobalSearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchValue, setSearchValue] = useState("");

  const resetSearch = () => {
    setSearchValue("");
  };

  return <GlobalSearchContext.Provider value={{ searchValue, setSearchValue, resetSearch }}>{children}</GlobalSearchContext.Provider>;
};

export const useGlobalSearch = () => useContext(GlobalSearchContext);
